<template>
  <footer
    class="bg-left bg-no-repeat bg-cover bg-[#151515] footer md:bg-contain"
    aria-labelledby="footerHeading"
  >
    <div class="container lg:pt-[72px] py-[30px] lg:pb-[50px]">
      <section
        class="flex flex-col items-center justify-between lg:gap-8 lg:flex-row lg:px-4"
      >
        <!-- Logo -->
        <div class="pb-5 lg:py-2 lg:pr-11">
          <nuxt-link :aria-label="$t('common.home_title')" :to="$i18nPath('/')">
            <Logo
              :color="'white'"
              class="lg:h-auto lg:w-[250px] xl:h-[74px] xl:w-[309.088px] w-[175.428px] h-[42px]"
            />
          </nuxt-link>
        </div>

        <!-- Social networks -->
        <div
          v-if="
            checkSocialExist &&
            currentSiteSettings &&
            currentSiteSettings.socials
          "
          class="flex flex-col items-center justify-between w-full gap-[14px] md:gap-2 md:flex-row lg:w-auto lg:justify-end"
        >
          <p class="text-primary text-sm leading-[18px] font-replica-light">
            {{ $t("common.find_us") }}
          </p>
          <div
            class="flex items-center justify-center -space-x-1 md:justify-end"
          >
            <a
              v-if="currentSiteSettings.socials.facebook"
              :href="currentSiteSettings.socials.facebook"
              target="_blank"
              aria-label="Facebook link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsFacebook class="w-14 h-14" />
            </a>
            <a
              v-if="currentSiteSettings.socials.instagram"
              :href="currentSiteSettings.socials.instagram"
              target="_blank"
              aria-label="Instagram link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsInstagram class="w-14 h-14" />
            </a>
            <a
              v-if="currentSiteSettings.socials.linkedin"
              :href="currentSiteSettings.socials.linkedin"
              target="_blank"
              aria-label="LinkedIn link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsLinkedIn class="w-14 h-14" />
            </a>
            <a
              v-if="currentSiteSettings.socials.twitter"
              :href="currentSiteSettings.socials.twitter"
              target="_blank"
              aria-label="Twitter link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsTwitter class="w-14 h-14" />
            </a>
            <a
              v-if="currentSiteSettings.socials.youtube"
              :href="currentSiteSettings.socials.youtube"
              target="_blank"
              aria-label="YouTube link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsYouTube class="w-14 h-14" />
            </a>
            <a
              v-if="currentSiteSettings.socials.tiktok"
              :href="currentSiteSettings.socials.tiktok"
              target="_blank"
              aria-label="TikTok link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsTikTok class="w-14 h-14" />
            </a>
          </div>
        </div>
      </section>

      <div
        v-if="hasMenuDisplay"
        class="overflow-hidden lg:px-4 pb-[60px] lg:pb-0 lg:grid lg:grid-cols-12 lg:mt-[35px] mt-[30px]"
      >
        <!-- Brand -->
        <Collapsible
          :aria-label="$config.APP_NAME"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="col-span-4 pt-6 pb-[11px] border-b border-secondary-200 border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-replica-bold text-primary-400 text-xs my-0"
              >
                {{
                  $t("common.brand_in_country", {
                    brand_name: $config.APP_NAME,
                    country_name: countryName,
                    interpolation: { prefix: "[:", suffix: "]" },
                  })
                }}
              </h3>
              <AngleDown
                class="inline w-5 h-5 ml-1 transition-transform lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <ul class="pt-[10px] mt-0">
            <!-- eslint-disable-next-line -->
            <li>
              <nuxt-link
                :to="$i18nPath('/stores')"
                class="text-xs leading-[18px] uppercase font-replica-pro text-white py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="$t('common.stores')"
              />
            </li>
            <li v-if="currentSite && currentSite.news">
              <nuxt-link
                :to="$i18nPath('/news')"
                class="text-xs leading-[18px] uppercase font-replica-pro text-white py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="$t('common.news')"
              />
            </li>
            <li
              v-if="currentSiteSettings && currentSiteSettings.become_a_partner"
            >
              <nuxt-link
                v-if="store.country === 'int'"
                :to="$i18nPath('/become-a-distributor')"
                class="text-xs leading-[18px] uppercase font-replica-pro text-white py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="$t('common.become_a_distributor')"
              />
              <nuxt-link
                v-else
                :to="$i18nPath('/become-a-dealer')"
                class="text-xs leading-[18px] uppercase font-replica-pro text-white py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="$t('common.become_a_dealer')"
              />
            </li>
          </ul>
        </Collapsible>

        <!-- Company -->
        <Collapsible
          :aria-label="$t('common.company')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="col-span-3 pt-6 pb-[11px] border-b border-secondary-200 border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-replica-bold text-primary-400 text-xs my-0"
                v-text="$t('common.company')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-primary lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <ul class="pt-[10px] my-0">
            <li>
              <nuxt-link
                :to="$i18nPath('/about-us')"
                class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="$t('common.about_us')"
              />
            </li>
            <li v-if="store.country === 'it'">
              <a
                href="https://cdn.bbike-cdn.com.cn/morbidelli.com/docs/quality_policy.pdf"
                target="_"
                class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
              >
                Politica della qualità
              </a>
            </li>

            <li>
              <nuxt-link
                v-if="currentSiteSettings && currentSiteSettings.work_with_us"
                :to="$i18nPath('/work-with-us')"
                class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="
                  $t('common.work_with_brand', {
                    brand: 'Morbidelli',
                    interpolation: { prefix: '[:', suffix: ']' },
                  })
                "
              />
            </li>
            <li
              v-if="
                currentSiteSettings && currentSiteSettings.test_ride_inquiry
              "
            >
              <nuxt-link
                :to="$i18nPath('/test-drive')"
                class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
              >
                {{ $t("common.test_ride_inquiry.title") }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Customer service -->
        <Collapsible
          :aria-label="$t('common.customer_services')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="col-span-3 pt-6 pb-[11px] border-b border-secondary-200 border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-replica-bold text-primary-400 text-xs my-0"
                v-text="$t('common.customer_services')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-primary lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <ul class="pt-[10px] my-0">
            <li
              v-if="
                currentSiteSettings && currentSiteSettings.intercom.integrate
              "
            >
              <a
                href=""
                class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                @click.prevent="intercom.show()"
                v-text="$t('common.chat_now')"
              />
            </li>

            <li>
              <nuxt-link
                :to="$i18nPath('/contact-us')"
                class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                v-text="$t('common.contact_us')"
              />
            </li>
          </ul>
        </Collapsible>

        <!-- Group companies -->
        <Collapsible
          v-if="state.group_companies.length"
          :aria-label="$t('common.group_companies')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="flex flex-col items-start col-span-2 pt-6 pb-[11px] border-b border-secondary-200 border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-replica-bold text-primary-400 text-xs my-0"
                v-text="$t('common.group_companies')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-primary lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <div class="flex flex-col lg:flex-row lg:justify-between">
            <ul class="pt-[10px] my-0">
              <li
                v-for="(company, index) in state.group_companies"
                :key="index"
              >
                <a
                  :href="company.url"
                  target="_blank"
                  class="text-xs uppercase font-replica-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
                >
                  {{ company.name }}
                </a>
              </li>
            </ul>
          </div>
        </Collapsible>
      </div>
      <div class="mt-[100px] lg:block hidden md:px-4">
        <div class="flex items-center justify-center">
          <CardSlider />
          <h3
            class="text-xl font-normal my-0 ml-[11.38px] text-white font-replica-light"
          >
            Beyond the Ride
          </h3>
        </div>
      </div>

      <!-- Footer below border -->
      <div
        class="relative flex flex-row items-center justify-between gap-8 px-2 pt-8 pb-24 lg:mt-[35px] border-t border-primary lg:px-6 md:gap-4 md:pb-0"
      >
        <div class="h-12">
          <KeewayGroupLogo class="text-dark-gray-100" />
        </div>

        <div
          class="absolute bottom-0 left-0 right-0 flex flex-col-reverse gap-4 text-xs md:mx-2 md:text-sm sm:mx-4 md:mx-auto md:pl-20 md:flex-col md:gap-0 md:relative md:bottom-auto md:right-auto md:left-auto"
        >
          <!-- Copyright -->
          <p class="font-normal text-center text-dark-gray-100">
            MBP MOTO s.r.l &copy; {{ $t("common.copyright") }} -
            {{ year }} Keeway -
            {{ $t("common.all_rights_reserved") }}
          </p>

          <!-- Legal -->
          <div
            class="flex flex-row items-center justify-between gap-1 md:justify-around md:gap-4"
          >
            <nuxt-link
              :to="$i18nPath('/terms')"
              class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
            >
              {{ $t("common.terms_and_conditions") }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/privacy-policy')"
              class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
            >
              {{ $t("common.privacy_policy") }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/cookie-policy')"
              class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
            >
              {{ $t("common.cookie_policy") }}
            </nuxt-link>
          </div>
        </div>

        <div>
          <!-- Locales -->
          <LocaleSelector
            class="flex justify-center md:block"
            name="footer"
            position="bottom"
          />
        </div>
      </div>
    </div>
    <WhatsappChat
      v-if="currentSiteSettings && currentSiteSettings.whatsapp"
      :phone="currentSiteSettings.whatsapp"
    />
    <MemeLink />
  </footer>
</template>
<script setup>
import { useMainStore } from "@/store/index";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import { useLocaleStore } from "@/store/locales";

const store = useMainStore();
const nuxtApp = useNuxtApp();
const localeStore = useLocaleStore();

const $i18nPath = nuxtApp.$i18nPath;
const $config = nuxtApp.$config.public;
const browserWidth = ref(0);
const hasMenuDisplay = ref(false);
const state = reactive({ group_companies: [] });
const year = ref(new Date().getFullYear());
const intercom = ref(
  typeof nuxtApp.$intercom !== "undefined" ? nuxtApp.$intercom : null
);

const products = store.products;
const sites = store.sites;
const country = store.country;
const locales = localeStore.defaultLocales;
const currentSiteSettings = store.currentSiteSettings;
const currentSite = store.currentSite;
const features = store.features;

const textClass = computed(() => {
  return store.locale === "el"
    ? "text-[17px] 2xl:text-[20px] font-bold"
    : "text-[30px]";
});

const countryName = computed(() => {
  const countryData = locales.length
    ? locales.find((x) => x.slug === country)
    : null;
  return countryData ? countryData.country.name : "";
});

const checkSocialExist = computed(() => {
  let data = [
    "facebook",
    "instagram",
    "linkedin",
    "twitter",
    "youtube",
    "tiktok",
  ];
  if (!currentSiteSettings) {
    return null;
  }
  return data.find((_v) => currentSiteSettings.socials[_v]);
});

const checkBrowserWidth = computed(() => {
  return browserWidth.value >= 1024 ? true : false;
});

onMounted(() => {
  const findData = features.find((_v) => _v.slug == "group-companies");

  if (findData && findData.settings) {
    state.group_companies = findData.settings;
  }
  browserWidth.value = window.innerWidth;
  hasMenuDisplay.value = true;
  window.addEventListener("resize", windowResized);
});

onUnmounted(() => {
  window.removeEventListener("resize", windowResized);
});

function windowResized() {
  browserWidth.value = window.innerWidth;
}
</script>

<style scoped>
.footer {
  background-image: url("/footer-bg-logo.svg");
}
@media screen and (max-width: 767px) {
  .footer {
    background-image: url("/mobile-footer-bg-logo.svg");
  }
}
</style>
